import { GET_BUILDINGS } from '@aarhus-university/au-lib-react-maps/src/actions/const';

const initialState = {
  Buildings: [],
};

export default (state = initialState, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case GET_BUILDINGS: {
      const { data } = action.payload;
      nextState.Buildings = data.locations;
      return nextState;
    }

    default: {
      return state;
    }
  }
};
